@import './settings';

.map {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}

.contact {
  position: absolute;
  width: 100%;
  z-index: 99999;
}

.msg {
  border-radius: 1em;
  clear: both;
  color: $white-color;
  margin: 1em;
  max-width: 80%;
  padding: 1em;
  position: relative;

  &::after {
    content: ' ';
    height: 0;
    position: absolute;
    top: 0;
    width: 0;
  }

  &-us {
    background: $primary-color;
    float: right;

    &::after {
      border: 1em solid $primary-color;
      border-bottom: 1em solid transparent;
      border-right: 1em solid transparent;
      right: -1em;
    }
  }

  &-user {
    background-color: $secondary-color;
    cursor: pointer;
    float: left;

    &::after {
      border: 1em solid $secondary-color;
      border-bottom: 1em solid transparent;
      border-left: 1em solid transparent;
      left: -1em;
    }
  }
}

$size: 9px;

.leaflet {
  &-container {
    font-size: inherit;

    a {
      color: $primary-color;
    }
  }

  // todo consider to use pseudo element to avoid tackling leaflet icon style
  // scss-lint:disable ImportantRule
  &-marker-icon {
    background: transparent;
    border: $size solid $primary-color;
    border-radius: 50%;
    height: $size * 2 !important;
    margin-left: - $size !important;
    margin-top: - $size !important;
    transition: .1s all;
    width: $size * 2 !important;

    $expanded: 4 * $size;

    &:hover {
      height: $expanded !important;
      margin-left: - $expanded / 2 !important;
      margin-top: - $expanded / 2 !important;
      transition: .25s all ease-in;
      width: $expanded !important;
    }
  }

  &-popup {
    bottom: 10px !important;
    font-family: 'Open Sans', sans-serif;

    &-content-wrapper {
      border-radius: 2px;
    }

    &-content-wrapper,
    &-tip {
      background: $secondary-color-light;
    }

    &-close-button {
      color: $primary-color !important;
    }
  }
  // scss-lint:enable ImportantRule
}
