@import './settings';

.work {
  overflow: hidden;
  position: relative;

  &:hover {
    .work-img {
      transform: scale(1.03);
      transition: 1s ease-in;
    }
  }

  &-img {
    background-position: center;
    background-size: cover;
    bottom: 0;
    filter: saturate(0) opacity(.2);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(1.001);
    transition: .5s ease-out;
    z-index: -1;
  }
}
