@import './settings';

.column {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 1rem;
  width: 100%;
}

@media screen and (min-width: $computer-breakpoint) {
  .container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    width: $computer-breakpoint;
  }

  .column {
    padding: 0 1rem;

    &.right {
      margin-left: 33.33%;
    }

    &.merged {
      &.one {
        width: 33.33%;
      }

      &.two {
        width: 66.66%;
      }

      &.three {
        width: 100%;
      }
    }

    &.half-height {
      height: 50vh;
    }

    &.full-height {
      height: 100vh;
    }
  }
}

// visibility

.computer-visible {
  display: none;
}

@media screen and (min-width: $computer-breakpoint) {
  .computer-visible {
    display: inherit;
  }
}
