// colors
$primary-color: #a6697a;
$primary-color-light: #d9ada9;
$primary-color-dark: #774f5d;

$secondary-color: #1c1c27;
$secondary-color-light: #dad0bf;

// breakpoints
$mobile-breakpoint: 320px;
$tablet-breakpoint: 768px;
$computer-breakpoint: 992px;
$large-monitor-breakpoint: 1400px;
$widescreen-monitor-breakpoint: 1900px;

$white-color: #fff;

$spacing: 1em;
