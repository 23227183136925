@import "settings";
@import "material-icons";
@import "layout";

body {
  overflow-x: hidden;
}

*,
::after,
::before {
  box-sizing: inherit;
}

a,
button {
  color: $primary-color;
  cursor: pointer;
  text-decoration: none;
}

button {
  background: none;
  font-size: 1rem;
  border: none;
}

html {
  box-sizing: border-box;
}

input[type="email"],
input[type="password"],
input[type="search"],
input[type="text"] {
  appearance: none;
}

body {
  background: $secondary-color;
  color: $secondary-color-light;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
}

h1 {
  font-size: 2.2em;
}

// center content
.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

// background
.bg-white {
  background: $white-color;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-primary-light {
  background-color: $primary-color-light;
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-secondary-light {
  background-color: $secondary-color-light;
}

.primary {
  color: $primary-color;
}

// crisp chat style
// scss-lint:disable ImportantRule, QualifyingElement, IdSelector, SelectorFormat, SelectorDepth
div.crisp-client .crisp-6k5puw .crisp-1v1s92e,
div.crisp-client .crisp-6k5puw .crisp-5e74az::before,
div.crisp-client .crisp-6k5puw .crisp-5e74az::after {
  background-color: $primary-color !important;
}

// cookiebot style
$border: 1px solid $secondary-color-light;

div#CybotCookiebotDialog {
  background-color: inherit;
  color: inherit;
  opacity: 0.95 !important;

  #CybotCookiebotDialogPoweredbyImage {
    filter: invert(1);
  }

  #CybotCookiebotDialogBodyContentTitle {
    color: inherit;
  }

  a#CybotCookiebotDialogBodyLevelDetailsButton {
    color: $secondary-color;
  }

  .CybotCookiebotDialogDetailBodyContentTab {
    background-color: $secondary-color;
    border-left: $border;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &:first-child {
      border-left: 0;
    }
  }

  #CybotCookiebotDialogDetailBodyContentTabs {
    display: flex;
    flex-direction: row-reverse;
  }

  a.CybotCookiebotDialogDetailBodyContentTabsItem {
    border-bottom: $border;
    border-left: $border;
    border-top: $border;
    color: $secondary-color-light !important;

    &Selected {
      border-bottom: 0;
      color: $secondary-color-light !important;
    }
  }

  a.CybotCookiebotDialogDetailBodyContentTabsItem:hover {
    background-color: $secondary-color-light !important;
    color: $secondary-color !important;
  }

  a#CybotCookiebotDialogBodyLevelButtonAccept {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 4px;
    color: inherit;
    margin-top: 5px;
    padding: 5px;
  }

  #CybotCookiebotDialogDetailBodyContent {
    background-color: $secondary-color;
    border: $border;
    color: inherit;
  }

  #CybotCookiebotDialogBodyLevelButtonsSelectPane {
    border: $border;
  }

  .CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
    background-color: $secondary-color;
    border-bottom: $border;
    border-right: $border;
    color: $secondary-color-light !important;

    &:hover {
      background-color: $secondary-color-light !important;
      color: $secondary-color !important;
    }

    &Selected {
      background-color: $secondary-color;
      color: $secondary-color-light !important;
      font-weight: 700;
    }
  }

  #CybotCookiebotDialogBodyLevelDetailsWrapper {
    background-color: $secondary-color-light;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

  .CybotCookiebotDialogDetailBodyContentCookieTypeTable {
    thead td,
    thead th {
      background-color: $secondary-color-light !important;
      color: $secondary-color;
    }

    tbody td {
      color: $secondary-color-light !important;
    }
  }

  #CybotCookiebotDialogDetail {
    background-color: $secondary-color;
  }

  #CybotCookiebotDialogDetailFooter {
    background-color: $secondary-color-light;
    color: $secondary-color;
  }
}
// scss-lint:enable ImportantRule, QualifyingElement, IdSelector, SelectorFormat, SelectorDepth
