@import './settings';

header {
  color: $secondary-color-light;
  font-size: 2em;
  padding: 1em;
  position: absolute;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: $tablet-breakpoint) {
  header {
    text-align: left;
  }
}
