@import './settings';

$size: 1em;

nav {
  display: none;
}

@media screen and (min-width: $computer-breakpoint) {
  nav {
    align-items: center;
    display: flex;
    height: 100%;
    position: fixed;

    right: $spacing;
    z-index: 1001;

    > ul {
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        color: $white-color;
        padding: $spacing / 2;
        text-align: right;

        // todo fix depth issues
        // scss-lint:disable NestingDepth, SelectorDepth
        > a {
          color: $primary-color;
          text-decoration: none;

          &.active {
            color: $secondary-color-light;

            &::after {
              background: $secondary-color-light;
              transition: .25s all;
            }
          }

          &::after {
            background: $primary-color;
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: $size;
            margin-left: .5em;
            transition: .1s all;
            width: $size;
          }
        }
        // scss-lint:enable NestingDepth, SelectorDepth
      }
    }
  }
}
