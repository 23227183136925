@import './settings';

$border: 1px solid rgba($primary-color-dark, .3);

.page {
  border-bottom: $border;
  height: 100vh;
  position: relative;
}

.front {
  z-index: 1000;
}

.grid-lines {
  &-left,
  &-right {
    height: 0;
    position: absolute;
    top: 0;
    width: 33.33%;
    z-index: 999;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 500vh;
      position: absolute;
      top: 0;
      width: 0;
    }

    &::before {
      border-left: $border;
    }

    &::after {
      border-right: $border;
      right: 0;
    }
  }

  &-right {
    right: 0;
  }
}

@media screen and (min-width: $computer-breakpoint) {
  main {
    &::before {
      width: $computer-breakpoint / 3;
    }

    &::after {
      width: $computer-breakpoint;
    }
  }
}

// modal
.modal {
  border: $border;
  bottom: 0;
  display: none;
  left: 0;
  padding: 1em;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000002;

  &-close {
    float: right;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .modal {
    border: $border;
    bottom: 1em;
    left: 1em;
    right: 1em;
    top: 1em;
  }
}

.modal-opened {
  overflow: hidden;

  .modal {
    display: block;
    overflow-y: scroll;
  }

  .overlay {
    background: rgba($secondary-color, .95);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000001;
  }
}

.ccs {
  background: #fff;
  height: 100px;
}